import tw, { styled } from "twin.macro";
import { SocialLinks } from "~/components/Base/SocialLinks";
import { Link } from "~/components/Base/Link";
import LogoWhiteSVG from "~/assets/icons/logo-white-shadows.svg";
import MobileItemDividerSVG from "~/assets/icons/footer-mobile-dotted-item-divider.svg";
import DesktopItemDividerSVG from "~/assets/icons/footer-desktop-dotted-item-divider.svg";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { up } from "~/utils/screens";

type FooterBackground = "transparent" | "gradientBase" | "gradientGreen" | "gradientPurple" | "gradientPurpleLighter";

interface Props {
  background?: FooterBackground;
}

const FooterContentContainer = styled.div<{ $background?: FooterBackground }>`
  ${tw`max-w-screen-max-content flex flex-col items-center justify-center px-20 pb-0 text-white mt-20 lg:(px-80 py-42 mt-0 mx-auto grid grid-cols-2 self-start text-white text-16 font-serif font-medium  grid-template-columns[2fr 10fr]) `}
`;

const FooterWrapper = styled.footer<{ $background?: FooterBackground }>`
  ${tw`w-full flex flex-col items-center justify-center mx-auto pb-4 `}
  ${({ $background }) => {
    switch ($background) {
      case "gradientBase":
        return tw`bg-gradientBase`;
      case "gradientGreen":
        return tw`bg-gradientGreen`;
      case "gradientPurple":
        return tw`bg-gradientPurple`;
      default:
        return tw`bg-transparent`;
    }
  }}
`;
const Brandcontainer = tw.div`height[100%] col-span-1 m-0`;

const LinksContainer = tw.div`flex flex-col text-center mt-24 pt-24 lg:(grid grid-cols-5 col-span-1 border-none mt-0 pt-0)`;

const LinksWrapper = tw.div`w-full mb-30 lg:mb-0 max-width[540px] column-gap[20px] row-gap[20px] lg:text-left`;
const LinksTitle = tw.div`font-bold font-serif`;
const LinksUl = tw.ul`font-sans`;
const LinksLi = tw.li`py-5`;

const StyledLink = tw(Link)`hover:underline`;

const LinksP = tw.p`block w-8/12 text-white text-10 text-center mt-22 mb-54 px-10 lg:(text-12 w-full)`;

export const Footer: React.FC<Props> = ({ background }) => {
  const isLg = useMediaQuery(up("lg"));
  return (
    <FooterWrapper $background={background}>
      <FooterContentContainer>
        <Brandcontainer>
          <Link href="/">
            <LogoWhiteSVG tw="w-108 h-96" />
          </Link>
        </Brandcontainer>
        <LinksContainer>
          <LinksWrapper>
            <LinksTitle>Cosa cerchi?</LinksTitle>
            <LinksUl>
              <LinksLi>
                <StyledLink href="/cerco-lavoro">Cerco Lavoro</StyledLink>
              </LinksLi>
              <LinksLi>
                <StyledLink href="/employer">Cerco Staff</StyledLink>
              </LinksLi>
            </LinksUl>
          </LinksWrapper>
          <LinksWrapper>
            <LinksTitle>Azienda</LinksTitle>
            <LinksUl>
              <LinksLi>
                <StyledLink href="/chi-siamo">Chi Siamo</StyledLink>
              </LinksLi>
              <LinksLi>
                <StyledLink href="/responsabilita-sociale">Responsabilità sociale</StyledLink>
              </LinksLi>
              <LinksLi>
                <StyledLink href="/lavora-con-noi">Lavora con noi</StyledLink>
              </LinksLi>
              <LinksLi>
                <StyledLink href="/diventa-partner">Diventa Partner</StyledLink>
              </LinksLi>
            </LinksUl>
          </LinksWrapper>
          <LinksWrapper>
            <LinksTitle>Supporto</LinksTitle>
            <LinksUl>
              <LinksLi>
                <StyledLink href="https://help.restworld.it/">FAQ</StyledLink>
              </LinksLi>
              {/* <LinksLi>
              <StyledLink href="/blog">Blog</StyledLink>
            </LinksLi> */}
              <LinksLi>
                <StyledLink href="/contatti">Contatti</StyledLink>
              </LinksLi>
            </LinksUl>
          </LinksWrapper>
          <LinksWrapper>
            <LinksTitle>Legal</LinksTitle>
            <LinksUl>
              <LinksLi>
                <StyledLink href="/privacy-policy">Privacy policy</StyledLink>
              </LinksLi>
              <LinksLi>
                <StyledLink href="/cookie-policy">Cookie policy</StyledLink>
              </LinksLi>
            </LinksUl>
          </LinksWrapper>
          {isLg && (
            <LinksWrapper>
              <SocialLinks />
            </LinksWrapper>
          )}
        </LinksContainer>
      </FooterContentContainer>
      {isLg ? <DesktopItemDividerSVG /> : <MobileItemDividerSVG />}
      {!isLg && <SocialLinks tw="width[fit-content] mt-20" />}
      <LinksP>
        {`© Copyright ${new Date().getFullYear()} Restworld S.r.l. Tutti i diritti riservati.`} | Restworld s.r.l
        società benefit P.IVA 12270120012 corso Castelfidardo 30/A 10129 Torino (TO)
      </LinksP>
    </FooterWrapper>
  );
};
