import tw, { styled } from "twin.macro";

interface Props {
  content?: string;
  color?: string;
  plainText?: boolean;
}

const Content = styled.div`
  ${tw`w-full`}

  h1, h2, h3, h4 {
    ${tw`font-serif my-30`}
  }

  h1 {
    ${tw`text-30 lg:text-40`}
  }

  h3 {
    ${tw`text-21 lg:text-24 mt-30 lg:mt-50 block`}
  }

  * + * {
    ${tw`mb-8`}
  }

  ol > li:first-child,
  ul > li:first-child {
    ${tw`mt-0`}
  }

  ol {
    ${tw`list-decimal`}
  }

  ul {
    ${tw`list-disc`}
  }

  strong,
  b {
    ${tw`font-bold`}
  }

  a {
    ${tw`underline`}
  }
`;

export const RichText: React.FC<Props> = ({ content, plainText, ...rest }) => {
  if (!content) return null;

  // Selct if rendering multi line code or injected HTML

  if (plainText) {
    return (
      <Content {...rest}>
        {content.split("\n").map((line, key) => {
          if (line.startsWith("Se ti piace questa posizione")) return null;
          return <p key={key}>{line}</p>;
        })}
      </Content>
    );
  }
  return <Content dangerouslySetInnerHTML={{ __html: content }} {...rest} />;
};
