import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useKey } from "react-use";
import tw, { styled } from "twin.macro";
import { keyframes } from "styled-components";
import CloseSVG from "~/assets/icons/close-color.svg";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  hasCloseButton?: boolean;
  isDismissable?: boolean;
  hasCard?: boolean;
}

const WrapperAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackdropAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 50%;
  }
`;

const CardAnimation = keyframes`
  from {
   transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
`;

const CloseButton = styled.div`
  ${tw`absolute z-60 cursor-pointer right-30 top-30 w-48 h-48`}

  > * {
    ${tw`w-full h-full`}
  }
`;
const ModalBackdrop = styled.div`
  ${tw`fixed z-40 w-screen h-full left-0 right-0 top-0 bottom-0 bg-black`}
  animation: ${BackdropAnimation} 300ms ease forwards;
`;

const ModalCard = styled.div`
  ${tw`relative z-50 w-full h-auto lg:max-width[1200px] lg:after:(content[""] h-60 relative block)`}
  animation: ${CardAnimation} 300ms ease forwards;
`;

const Wrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`fixed flex justify-center overflow-y-auto lg:py-60 z-50 w-screen h-auto max-height[100vh] max-width[100vw] top-0 right-0 bottom-0 left-0`}
  animation: ${WrapperAnimation} 300ms ease forwards;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Modal: React.FC<Props> = ({
  children,
  isOpen,
  closeModal,
  hasCloseButton,
  isDismissable,
  hasCard = true,
  ...rest
}) => {
  useKey("Escape", () => closeModal());
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!modalRef?.current) return;
    disableBodyScroll(modalRef.current);
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <Wrapper $isOpen={isOpen} {...rest} ref={modalRef}>
      {hasCard && (
        <ModalCard>
          {hasCloseButton && (
            <CloseButton onClick={closeModal}>
              <CloseSVG />
            </CloseButton>
          )}
          {children}
        </ModalCard>
      )}
      {!hasCard && children}
      <ModalBackdrop onClick={isDismissable ? closeModal : () => null} />
    </Wrapper>,
    document.body
  );
};
