interface Props {
  src?: string;
  alt?: string;
}

export const Image: React.FC<Props> = ({ src, alt = "", ...rest }) => {
  if (!src) return null;

  return <img src={src} alt={alt} loading="lazy" {...rest} />;
};
