import tw, { styled } from "twin.macro";
import { Link } from "~/components/Base";
import FacebookSVG from "~/assets/icons/facebook-footer.svg";
import InstagramSVG from "~/assets/icons/instagram-footer.svg";
import LinkedinSVG from "~/assets/icons/linkedin-footer.svg";
import TelegramSVG from "~/assets/icons/telegram.svg";
import HelpIconSVG from "~/assets/icons/help-icon.svg";

const SocialsContainer = tw.div`col-span-1 mt-10`;
const SocialsUl = tw.ul`flex items-end h-full column-gap[40px] lg:(grid grid-cols-2 items-start column-gap[40px] row-gap[20px] text-right grid-template-columns[2fr 10fr])`;
const SocialsLi = styled.div`
  ${tw`w-26 h-26 lg:(width[30px] height[30px])`}
  svg {
    ${tw`w-full h-full`}
  }
`;

type SocialType = "facebook" | "instagram" | "linkedin" | "telegram" | "help";

interface Props {
  items?: SocialType[];
}

export const SocialLinks: React.FC<Props> = ({
  items = ["facebook", "instagram", "linkedin", "telegram"],
  ...rest
}) => {
  return (
    <SocialsContainer {...rest}>
      <SocialsUl>
        {items.includes("facebook") && (
          <SocialsLi>
            <Link href="https://www.facebook.com/Restworld.it/">
              <FacebookSVG />
            </Link>
          </SocialsLi>
        )}
        {items.includes("instagram") && (
          <SocialsLi>
            <Link href="https://www.instagram.com/restworld_hr/">
              <InstagramSVG />
            </Link>
          </SocialsLi>
        )}
        {items.includes("linkedin") && (
          <SocialsLi>
            <Link href="https://www.linkedin.com/company/restworld/">
              <LinkedinSVG />
            </Link>
          </SocialsLi>
        )}
        {items.includes("telegram") && (
          <SocialsLi>
            <Link href="https://t.me/restworld_offerte">
              <TelegramSVG />
            </Link>
          </SocialsLi>
        )}
        {items.includes("help") && (
          <SocialsLi>
            <Link href="https://help.restworld.it">
              <HelpIconSVG />
            </Link>
          </SocialsLi>
        )}
      </SocialsUl>
    </SocialsContainer>
  );
};
