import { useState, ReactElement, useRef } from "react";
import tw, { css, styled } from "twin.macro";
import { useClickAway } from "react-use";
import { up } from "~/utils/screens";
import { Link } from "~/components/Base";
import { isTouchDevice } from "~/utils/common";

import ToolTipSVG from "~/assets/icons/tooltip.svg";

type Background = "lightGrey" | "violet";
type Direction = "up" | "right" | "left";

interface Props {
  content?: string;
  delay?: number;
  icon?: ReactElement;
  direction?: Direction;
  bgColor?: Background;
  link?: {
    label: string;
    url: string;
  };
}

const Wrapper = tw.div`relative pointer-events-none`;

export const Tip = styled.div`
  ${tw`rounded-xl bg-lightgrey text-white py-8 px-12 text-12 w-200 text-left`}
`;

export const Container = styled.div<{ $show: boolean; $bgColor?: Background; $direction?: Direction }>`
  ${tw`absolute right-0 z-10 top-1/2 invisible opacity-0 pointer-events-auto`}
  transform: translate(calc(50% - 10px), calc(-100% - 25px));
  transition: visibility 400ms ease, opacity 400ms ease;

  &:before {
    ${tw`w-0 h-0 absolute left-1/2`}
    content: "";
    border: solid transparent;
    border-width: 10px;
    border-top-color: #9c9cb7;
    bottom: -20px;
    transform: translateX(-50%);
  }

  ${({ $show }) => $show && tw`visible opacity-100`}
  ${({ $direction }) =>
    $direction === "right" &&
    css`
      ${up("sm")} {
        transform: translate(calc(100% + 20px), -50%);

        &:before {
          ${tw`-ml-10 top-1/2`}
          border: solid transparent;
          border-width: 10px;
          left: -10px;
          transform: translateY(-50%);
          border-right-color: #9c9cb7;
        }
      }
    `}

  ${({ $direction }) =>
    $direction === "left" &&
    css`
      transform: translate(-50px, -50%);

      &:before {
        ${tw`hidden`}
      }

      &:after {
        ${tw`w-0 h-0 absolute right-0 top-1/2`}
        content: "";
        border: solid transparent;
        border-width: 10px;
        border-top-color: #9c9cb7;
        transform: translateX(100%) translateY(-25%);
      }
    `}

  ${({ $bgColor }) => {
    switch ($bgColor) {
      case "violet":
        return css`
          ${Tip} {
            ${tw`bg-violet`}
          }

          &:before,
          &:after {
            border-top-color: #4a2983;
          }

          &:after {
            border-left-color: #4a2983;
          }

          ${up("sm")} {
            &:before {
              /* border: solid transparent;
              border-width: 10px; */
              border-right-color: #4a2983;
            }
          }
        `;
      case "lightGrey":
      default:
        return tw`bg-lightgrey`;
    }
  }}
`;

const Button = styled.button`
  ${tw`ml-8 pointer-events-auto`}

  svg {
    ${tw`pointer-events-none`}
  }
`;

export const Tooltip: React.FC<Props> = ({ content, direction, icon, bgColor, link }) => {
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useClickAway(wrapperRef, () => setShow(false));

  const toggleShow = () => {
    if (!isTouchDevice()) return;
    setShow((prev) => !prev);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Button onMouseEnter={() => (!isTouchDevice() ? setShow(true) : null)} onClick={toggleShow}>
        {!!icon ? icon : <ToolTipSVG />}
      </Button>
      {!!content && (
        <Container
          $show={show}
          $bgColor={bgColor}
          $direction={direction}
          onMouseLeave={() => (!isTouchDevice() ? setShow(false) : null)}
        >
          <Tip>
            <div>{content}</div>
            {!!link && (
              <Link tw="underline" href={link.url}>
                {link.label}
              </Link>
            )}
          </Tip>
        </Container>
      )}
    </Wrapper>
  );
};
