import React from "react";
import tw, { styled } from "twin.macro";
import { RichText } from "./RichText";
import AlertSVG from "~/assets/icons/alert-violet.svg";

interface Props {
  content: string;
  isVisible?: boolean;
}

const IconWrapper = tw.div``;

const Content = styled.div`
  ${tw`mt-20 text-left md:(ml-20 mt-0)`}

  a {
    ${tw`text-violet underline`}
  }
`;

const Wrapper = styled.div<{ $isVisible?: boolean }>`
  ${tw`w-full bg-violet bg-opacity-10 text-grey p-20 rounded-xl flex-col md:flex-row invisible hidden opacity-0`}

  ${({ $isVisible }) => $isVisible && tw`flex visible opacity-100`}
`;

export const FeedbackAlert: React.FC<Props> = ({ content, isVisible = true, ...rest }) => {
  return (
    <Wrapper $isVisible={isVisible} {...rest}>
      <IconWrapper>
        <AlertSVG />
      </IconWrapper>
      <Content>
        <RichText content={content} />
      </Content>
    </Wrapper>
  );
};
