import { useState } from "react";
import tw from "twin.macro";
import { RichText, Button } from "~/components/Base";

interface Props {
  content: string;
  length?: number;
}

const ButtonWrapper = tw.div`mt-10`;

export const ReadMoreLess: React.FC<Props> = ({ content, length = 500, ...rest }) => {
  const [isFullLength, setIsFullLength] = useState<boolean>(false);

  return (
    <>
      <RichText content={isFullLength ? content : `${content.slice(0, length)} [...]`} {...rest} />
      <ButtonWrapper>
        <Button onClick={() => setIsFullLength(!isFullLength)} tw="mt-4" $state="activeGreen">
          {isFullLength ? "Leggi di meno" : "Leggi di più"}
        </Button>
      </ButtonWrapper>
    </>
  );
};
