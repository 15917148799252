import { useState, useCallback, useEffect } from "react";
import tw, { styled } from "twin.macro";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "react-use";
import { Link, Button, MobileNav, Spinner } from "~/components/Base";
import { useUserData } from "~/hooks/useUserData";
import { getRequest } from "~/utils/api";
import { Asset } from "~/pages/worker/edit_old";
import ProfileAvatarWhite from "~/assets/icons/avatar.svg";

import LogoSVGWhite from "~/assets/logos/restworld-horizontal-white.svg";
import LogoSVGBlue from "~/assets/logos/restworld-horizontal-blue.svg";
import JobSearchIconSVG from "~/assets/icons/nav/job-search-icon.svg";
import ResponsibilityIconSVG from "~/assets/icons/nav/responsibility-icon.svg";
import StaffSearchIconSVG from "~/assets/icons/nav/staff-search-icon.svg";
import AboutIconSVG from "~/assets/icons/nav/about-icon.svg";

export const MenuIcon = (Icon: any, isWhiteLayout: boolean) => {
  if (!Icon) return <></>;
  if (!isWhiteLayout) {
    const LightIcon = styled(Icon)`
      ${tw`mb-5 mr-10`}
    `;
    return (
      <>
        <LightIcon />
      </>
    );
  }
  const DarkIcon = styled(Icon)`
    ${tw`mb-5 mr-10`}
    path {
      fill: #365888;
    }
  `;
  return <DarkIcon />;
};

type Layouts =
  | "base"
  | "purple"
  | "white"
  | "gradientGreen"
  | "gradientDark"
  | "gradientBlue"
  | "gradientGreen2"
  | "gradientYellow"
  | "gradientPink"
  | "gradientRed"
  | "gradientPurple";

interface Props {
  layout?: Layouts;
}

export const ProfilePicture = tw.img`w-25 h-25 rounded-full`;

export const BrandLink = styled(Link)`
  ${tw`relative z-30 bg-green`}
`;

export const Ul = tw.ul`flex items-end px-10 lg:px-20 bg-red border-2`;
export const Li = tw.li`relative flex items-center px-10 xl:px-30 font-medium text-16 lg:text-base border-4`;

export const RightContainer = tw.div`items-center flex`;

export const NavbarContent = tw.div`max-w-screen-max-content w-full flex justify-between items-center p-20 lg:(py-40 px-80) bg-black`;

const checkLayoutScrolled = (layout: Layouts) => {
  switch (layout) {
    case "base":
      return tw`bg-gradientBase box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "white":
      return tw`bg-white text-blue box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "purple":
      return tw`bg-gradientPurple box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientGreen":
      return tw`bg-gradientGreen box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientDark":
      return tw`bg-gradientDark box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientBlue":
      return tw`bg-gradientBlue box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientGreen2":
      return tw`bg-gradientGreen2 box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientYellow":
      return tw`bg-gradientYellow box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientPink":
      return tw`bg-gradientPink box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientRed":
      return tw`bg-gradientRed box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    case "gradientPurple":
      return tw`bg-gradientPurple box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
    default:
      return tw`bg-gradientBase box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`;
  }
};

export const DesktopNav = styled.nav<{ $layout?: Layouts; $isScrolled: boolean }>`
  ${tw`fixed z-20 top-0 left-1/2 transform -translate-x-1/2 w-full hidden md:flex justify-center font-serif items-center text-white`}

  ${({ $layout }) => $layout === "white" && tw`text-blue`}

  ${({ $isScrolled, $layout }) => $isScrolled && checkLayoutScrolled($layout || "base")}
`;

export const Navbar: React.FC<Props> = ({ layout }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [candidateAssets, setCandidateAssets] = useState<Asset[]>([]);

  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [jwt] = useLocalStorage("jwt");
  const { isLoading, userData } = useUserData();

  const fetchWorkerAssets = useCallback(async (id: string, jwt: string) => {
    setLoading(true);
    const getWorkerAsset = async (url: string) => {
      const { data } = await getRequest(`/workers/asset?url=${url}`, {
        responseType: "blob",
      });
      return data;
    };
    try {
      const res = await getRequest(`/workers/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (res.status === 200) {
        const candidate = res.data;
        if (!candidate || !candidate["assets"] || !candidate["assets"].length) {
          return;
        }

        const toBeFetched = (candidate["assets"] as any[])?.map((asset) => asset.url);
        toBeFetched.map(async (url: string) => {
          const reader = new FileReader();
          const asset = await getWorkerAsset(url);
          reader.readAsDataURL(asset);
          reader.onload = () => {
            const base64 = reader.result;
            setCandidateAssets((prev) => [...prev, { src: base64 as string, url, uid: uuidv4() }]);
          };
        });
      }
    } catch (_e) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && userData && userData.id && jwt) {
      fetchWorkerAssets(userData.id as string, jwt as string);
    }
  }, [userData, isLoading, fetchWorkerAssets, jwt]);

  useScrollPosition(({ currPos }) => {
    setIsScrolled(Math.abs(currPos.y) > 20);
  });

  const isWhiteLayout: boolean = !!layout && layout === "white";

  return (
    <>
      {/* {loading && (
        <div tw="flex items-center justify-center">
          <Spinner color="white" />
        </div>
      )} */}
      <DesktopNav $layout={layout || "base"} $isScrolled={isScrolled}>
        <NavbarContent>
          <BrandLink href="/">
            {isWhiteLayout ? <LogoSVGBlue tw="h-30 lg:h-44" /> : <LogoSVGWhite tw="h-30 lg:h-44" />}
          </BrandLink>
          <RightContainer>
            <Ul>
              <Li>
                {MenuIcon(JobSearchIconSVG, isWhiteLayout)}
                <Link href="/cerco-lavoro">Cerco lavoro</Link>
              </Li>
              <Li>
                {MenuIcon(StaffSearchIconSVG, isWhiteLayout)}
                <Link href="/employer">Cerco staff</Link>
              </Li>
              <Li>
                {MenuIcon(ResponsibilityIconSVG, isWhiteLayout)}
                <Link href="/responsabilita-sociale">Responsabilità sociale</Link>
              </Li>
              <Li>
                {MenuIcon(AboutIconSVG, isWhiteLayout)}
                <Link href="/chi-siamo">Chi siamo</Link>
              </Li>
            </Ul>
            <Button as={Link} href="/login" $state={!!layout && layout === "white" ? "activeGreen" : "base"}>
              {isLoading ? "Accedi" : !!userData ? "Profilo" : "Accedi"}
            </Button>
          </RightContainer>
        </NavbarContent>
      </DesktopNav>
      {/*<MobileNav
        layout={layout || "base"}
        profileImg={
          candidateAssets && candidateAssets.length ? (
            <ProfilePicture tw="mr-10 inline-block" alt="worker" src={candidateAssets[0].src} />
          ) : (
            <ProfileAvatarWhite tw="mr-10 inline-block" />
          )
        }
        isLoading={isLoading}
        userData={userData}
      /> */}
    </>
  );
};

export type { Layouts };
