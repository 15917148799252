import tw, { styled } from "twin.macro";

export type ButtonState =
  | "base"
  | "activeGreen"
  | "activeWhite"
  | "activePurple"
  | "activeGrey"
  | "purpleWhite"
  | "borderWhite"
  | "borderBlue"
  | "inactive"
  | "green-base";
type Size = "default" | "big";

const handleColorType = (state?: ButtonState) => {
  switch (state) {
    case "activeGreen":
      return tw`border-transparent text-black bg-pear font-semibold!`;
    case "activePurple":
      return tw`border-transparent text-white bg-purple font-semibold!`;
    case "activeWhite":
      return tw`border-white bg-white text-black`;
    case "purpleWhite":
      return tw`border-transparent bg-white text-violet! hover:bg-violet! hover:text-white!`;
    case "activeGrey":
      return tw`border-transparent bg-grey5 font-semibold! text-grey`;
    case "borderWhite":
      return tw`border-white! text-white! hover:bg-white! hover:text-black!`;
    case "borderBlue":
      return tw`border-blue text-blue! text-opacity-80 hover:text-opacity-100`;
    case "inactive":
      return tw`border-transparent bg-white bg-opacity-40 text-white`;
    case "green-base":
      return tw`bg-baseSuccessLight rounded-lg text-baseSuccessSolid border-none focus:ring-offset-2 hover:opacity-80`;
    case "base":
    default:
      return tw`border-white text-white has-hover:hover:(border-transparent bg-pear text-black)`;
  }
};
const handleSize = (size?: Size) => {
  switch (size) {
    case "default":
      return tw`py-12`;
    case "big":
      return tw`py-20! text-18 text-blue font-medium`;
    default:
      return tw`py-12`;
  }
};

export const Button = styled.button<{ $state?: ButtonState; $size?: Size }>`
  ${tw`border rounded-full py-12 h-36 px-24 font-normal text-14 font-serif inline-flex justify-center items-center whitespace-nowrap`}

  ${({ $state }) => handleColorType($state)}
  ${({ $size }) => handleSize($size)}
`;
