import tw, { styled } from "twin.macro";
import { SocialLinks, Image } from "~/components/Base";

import CircleSVG from "~/assets/icons/list-circle.svg";

interface Props {
  illustration?: string;
}

const CircleWrapper = tw.div`w-15 md:w-16 float-left mt-2 relative z-10`;

const IllustrationWrapper = tw.div`h-auto w-2/3 mx-auto mb-40 md:(w-1/2 pl-10 -mb-30 -mr-30)`;

const Illustration = tw((props) => <Image {...props} />)`w-full h-full`;

const HeadingWrapper = tw.div`flex`;

const ContentWrapper = tw.div`flex flex-col items-center md:(items-end flex-row)`;

const Title = tw.h1`text-30 sm:text-40 font-bold mb-23 font-serif`;

const Paragraph = tw.h5`text-30 font-serif`;

const ListTitle = tw.h5`font-serif text-24 font-bold`;

const ListParagraph = tw.h5`font-sans mt-2 md:w-4/5`;

const ListContent = styled.div`
  ${tw`ml-34 mb-50`}
`;

const ConnectedListItem = styled.li`
  ${tw`relative m-0 pb-1 pl-5 -mt-3`}

  &::before {
    ${tw`content[''] absolute w-2 bg-pear top-19 bottom-0 left-13`}
  }
  &:last-child {
    &::before {
      ${tw`hidden`}
    }
    ${ListContent} {
      ${tw`mb-0`}
    }
  }
`;

const list = [
  {
    label: "Effettua il login",
    paragraph: "Clicca su Entra in basso e accedi al tuo account",
  },
  {
    label: "Codici di accesso",
    paragraph: "Scegli un tuo personale codice di accesso",
  },
  {
    label: "Inizia il gioco!",
    paragraph: "Renditi visibile e migliora il tuo CV nativo raccontando le tue esperienze pregresse",
  },
];

export const ThankYou: React.FC<Props> = ({ illustration }) => {
  return (
    <>
      <HeadingWrapper>
        <div tw="flex-1">
          <Title>Benvenuto!</Title>
          {illustration && (
            <IllustrationWrapper tw="block md:hidden">
              <Illustration src={illustration} alt="Illustration" />
            </IllustrationWrapper>
          )}
          <Paragraph>
            E ora?
            <br />È semplicissimo!
          </Paragraph>
        </div>
        {illustration && (
          <IllustrationWrapper tw="hidden md:block">
            <Illustration src={illustration} alt="Illustration" />
          </IllustrationWrapper>
        )}
      </HeadingWrapper>
      <ContentWrapper>
        <ul tw="mt-40">
          {list &&
            list.length > 0 &&
            list.map((item) => (
              <ConnectedListItem>
                <CircleWrapper>
                  <CircleSVG />
                </CircleWrapper>
                <ListContent>
                  {item.label && <ListTitle>{item.label}</ListTitle>}
                  {item.paragraph && <ListParagraph>{item.paragraph}</ListParagraph>}
                </ListContent>
              </ConnectedListItem>
            ))}
        </ul>
        <SocialLinks tw="w-auto mt-40 md:(w-1/4 ml-20)" />
      </ContentWrapper>
    </>
  );
};
