import { useEffect } from "react";
import { useRouter } from "next/router";

interface Props {
  to: string;
}

export function Redirect({ to }: Props) {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady && !!to) {
      router.push(to);
    }
  }, [to, router.isReady, router]);

  return null;
}
