import tw, { styled } from "twin.macro";
import { Button, Modal } from "~/components/Base";

import CloseSVG from "~/assets/icons/redirect-modal-close.svg";

type Cta = {
  text: string;
  callback: () => void;
};

interface Props {
  title: string;
  text?: string;
  render?: boolean;
  leftCta?: Cta;
  rightCta?: Cta;
  closeCallback: () => void;
}

const Frame = styled.div`
  ${tw`absolute top-1/2 left-1/2 w-full bg-white overflow-hidden border-radius[50px] max-width[480px] rounded-bl-none px-24 pt-24 lg:px-60 lg:pt-60`}
  box-shadow: -10px 10px 0px 0px rgba(0, 0, 0, .2);
  transform: translate(-50%, -50%);
`;

const Title = tw.h1`text-30 font-serif font-bold mb-18 bg-clip-text text-transparent bg-gradient-to-r from-violet to-purple pr-60`;

const Text = tw.p`text-16 text-black mb-120`;

const Banner = tw.div`w-full bg-gradientBase h-80 lg:h-88 flex items-center justify-end px-24 lg:px-60 absolute inset-x-0 bottom-0`;

const CloseButton = tw.button`absolute top-0 right-0 p-24 lg:p-32`;

export const Prompt: React.FC<Props> = ({ render, title, text, leftCta, rightCta, closeCallback }) => {
  if (!render) return null;

  return (
    <Modal isOpen={render} isDismissable closeModal={closeCallback}>
      <Frame>
        <CloseButton onClick={closeCallback}>
          <CloseSVG tw="w-32 h-32 lg:w-40 lg:h-40" />
        </CloseButton>
        {!!title && <Title>{title}</Title>}
        {!!text && <Text>{text}</Text>}
        <Banner>
          {!!leftCta && (
            <Button tw="mr-20" onClick={leftCta.callback}>
              {leftCta.text}
            </Button>
          )}
          {!!rightCta && (
            <Button onClick={rightCta.callback} $state="activeGreen">
              {rightCta.text}
            </Button>
          )}
        </Banner>
      </Frame>
    </Modal>
  );
};
