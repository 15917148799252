import NextLink from "next/link";
import tw, { styled } from "twin.macro";

interface Props {
  href: string;
  target?: string;
  $isColored?: boolean;
}

const StyledLink = styled.a<{ $isColored?: boolean }>`
  ${`hover:no-underline`}
  ${({ $isColored }) => $isColored && `color[#4975b6]`}
`;

export const Link: React.FC<Props> = ({ href, target, children, $isColored, ...rest }) => {
  const isInternalLink = !href?.startsWith("http") && !href?.startsWith("mailto:") && !href?.startsWith("tel:");

  if (isInternalLink) {
    return (
      <NextLink href={href} passHref>
        <StyledLink target={target || "_self"} $isColored={$isColored} {...rest}>
          {children}
        </StyledLink>
      </NextLink>
    );
  }

  return (
    <StyledLink href={href} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
    </StyledLink>
  );
};
