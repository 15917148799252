import tw, { styled } from "twin.macro";
import { keyframes } from "styled-components";

type Color = "white" | "violet";

interface Props {
  color: Color;
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Wrapper = styled.div<{ $color: Color }>`
  ${tw`w-40 h-40`}

  animation: ${rotate} 1s linear infinite;

  ${({ $color }) => {
    switch ($color) {
      case "white":
        return tw`text-white`;
      case "violet":
        return tw`text-violet`;
    }
  }}
`;

const StyledSpinner = styled.svg`
  ${tw`w-full h-full`}

  & .path {
    stroke: currentColor;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

export const Spinner: React.FC<Props> = ({ color, ...rest }) => {
  return (
    <Wrapper $color={color} {...rest}>
      <StyledSpinner viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
      </StyledSpinner>
    </Wrapper>
  );
};
