import tw, { styled } from "twin.macro";
import { useState, useEffect } from "react";

export type Gradient =
  | "gradientDark"
  | "gradientBlue"
  | "gradientPurple"
  | "gradientGreen2"
  | "gradientYellow"
  | "gradientPink"
  | "gradientRed";

interface Props {
  gradient?: Gradient;
  onGradientChanged: (gradient: Gradient) => void;
}

const ColorsWrapper = tw.div`flex justify-center items-center`;
const ColorItem = styled.div`
  ${tw`rounded-full h-36 w-36 p-4 cursor-pointer mx-5`}

  &.active {
    ${tw`border border-violet`}
  }
`;
const ColorCoreDark = tw.div`rounded-full h-full w-full bg-gradientDark`;
const ColorCoreBlue = tw.div`rounded-full h-full w-full bg-gradientBlue`;
const ColorCorePurple = tw.div`rounded-full h-full w-full bg-gradientPurple`;
const ColorCoreGreen2 = tw.div`rounded-full h-full w-full bg-gradientGreen2`;
const ColorCoreYellow = tw.div`rounded-full h-full w-full bg-gradientYellow`;
const ColorCorePink = tw.div`rounded-full h-full w-full bg-gradientPink`;
const ColorCoreRed = tw.div`rounded-full h-full w-full bg-gradientRed`;

const gradientList = [
  { name: "gradientDark", core: <ColorCoreDark /> },
  { name: "gradientBlue", core: <ColorCoreBlue /> },
  { name: "gradientPurple", core: <ColorCorePurple /> },
  { name: "gradientGreen2", core: <ColorCoreGreen2 /> },
  { name: "gradientYellow", core: <ColorCoreYellow /> },
  { name: "gradientPink", core: <ColorCorePink /> },
  { name: "gradientRed", core: <ColorCoreRed /> },
];

export const GradientPicker: React.FC<Props> = ({ gradient, onGradientChanged }) => {
  const [activeGradient, setActiveGradient] = useState<Gradient>("gradientPurple");
  const handleChange = (newGradient: Gradient) => {
    setActiveGradient(newGradient);
    onGradientChanged(newGradient);
  };

  useEffect(() => {
    setActiveGradient(gradient ?? "gradientPurple");
  }, [gradient]);

  return (
    <ColorsWrapper>
      {gradientList.map((g, i) => (
        <ColorItem
          key={i}
          onClick={() => handleChange(g.name as Gradient)}
          className={activeGradient === g.name ? "active" : ""}
        >
          {g.core}
        </ColorItem>
      ))}
    </ColorsWrapper>
  );
};
