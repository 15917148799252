import { useCallback, useState } from "react";
import { UserData } from "types";
import { useLocalStorage } from "react-use";
import { getRequest } from "~/utils/api";

export const useUserData = () => {
  const [jwt] = useLocalStorage("jwt");
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUserData = useCallback(async (parentJWT?: string) => {
    const token = parentJWT || localStorage.getItem("jwt");

    if (!token) {
      return null;
    }
    setIsLoading(true);

    try {
      const res = await getRequest("/accounts/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setUserData(res.data.data);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }, []);

  return { isLoading, userData, fetchUserData };
};
