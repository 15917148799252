import tw, { styled, css } from "twin.macro";
import ReactPlayer from "react-player";

interface Props {
  url?: string;
  controls?: boolean;
  forceRatio?: boolean;
}

const StyledVideo = styled(ReactPlayer)``;

const VideoWrapper = styled.div<{ $forceRatio: boolean }>`
  ${tw`relative bg-transparent w-full text-center`}

  video {
    ${tw`max-height[100vh]`}
  }

  ${({ $forceRatio }) =>
    $forceRatio &&
    css`
      ${tw`pb-9/16`}
      ${StyledVideo} {
        ${tw`absolute left-0 top-0 right-0 bottom-0 w-full h-full`}
      }
    `}
`;

export const Video: React.FC<Props> = ({ url, controls = false, forceRatio = false }) => {
  return (
    <VideoWrapper $forceRatio={!!forceRatio}>
      <StyledVideo
        url={url}
        width="100%"
        height={forceRatio ? "100%" : "auto"}
        controls={controls}
        config={{
          youtube: {
            playerVars: { controls: 1 },
          },
        }}
      />
    </VideoWrapper>
  );
};
