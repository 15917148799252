import { useEffect, useState, useRef } from "react";
import tw, { styled } from "twin.macro";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useRouter } from "next/router";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { UserData } from "types";
import { Link, Button, SocialLinks } from "~/components/Base";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { up } from "~/utils/screens";

import HamburgerCloseSVG from "~/assets/icons/hamburger-close.svg";
import HamburgerSVGWhite from "~/assets/icons/hamburger.svg";
import HamburgerSVGBlue from "~/assets/icons/hamburger-blue.svg";
import WhiteLogoSVG from "~/assets/logos/restworld-horizontal-white.svg";
import BlueLogoSVG from "~/assets/logos/restworld-horizontal-blue.svg";

import AboutIconSVG from "~/assets/icons/nav/about-icon.svg";
import JobSearchIconSVG from "~/assets/icons/nav/job-search-icon.svg";
import PartnerIconSVG from "~/assets/icons/nav/partner-icon.svg";
import ResponsibilityIconSVG from "~/assets/icons/nav/responsibility-icon.svg";
import StaffSearchIconSVG from "~/assets/icons/nav/staff-search-icon.svg";
import HelpIconSVG from "~/assets/icons/help-icon-sidebar.svg";

interface Props {
  layout?: Layouts;
  isLoading?: boolean;
  userData?: UserData | null;
  profileImg?: any;
}

type Layouts =
  | "base"
  | "purple"
  | "white"
  | "gradientGreen"
  | "gradientDark"
  | "gradientBlue"
  | "gradientGreen2"
  | "gradientYellow"
  | "gradientPink"
  | "gradientRed"
  | "gradientPurple";

export const BrandLink = styled(Link)`
  ${tw`relative z-40`}
`;

export const Ul = tw.ul`flex flex-col items-start mb-15 sm:mb-30`;

export const Li = tw.li`relative flex items-center w-auto text-21 sm:text-24 font-bold py-13 sm:py-20`;

export const MenuContent = tw.div`w-full mt-80 sm:mt-128 mb-25 sm:mb-50 text-white`;

export const MenuButton = tw.button``;

const ItemDivider = tw.div`w-full height[1px] bg-gradient-to-r from-transparent via-white to-transparent my-10`;

export const MobileNavbar = styled.div<{ $isScrolled: boolean; $layout: Layouts; $isOpen: boolean }>`
  ${tw`fixed top-0 w-full z-40 flex md:hidden justify-between font-serif items-center p-20 text-white bg-transparent`}

  ${({ $isScrolled, $layout }) => {
    if ($isScrolled && $layout === "base") return tw`bg-gradientBase shadow-xl`;
    if ($isScrolled && $layout === "gradientGreen") return tw`bg-gradientGreen shadow-xl`;
    if ($isScrolled && $layout === "purple") return tw`bg-gradientPurple shadow-xl`;
    if ($isScrolled && $layout === "white") return tw`bg-white text-blue shadow-xl`;
    if ($isScrolled && $layout === "gradientDark") return tw`bg-gradientDark shadow-xl`;
    if ($isScrolled && $layout === "gradientBlue") return tw`bg-gradientBlue shadow-xl`;
    if ($isScrolled && $layout === "gradientGreen2") return tw`bg-gradientGreen2 shadow-xl`;
    if ($isScrolled && $layout === "gradientYellow") return tw`bg-gradientYellow shadow-xl`;
    if ($isScrolled && $layout === "gradientPink") return tw`bg-gradientPink shadow-xl`;
    if ($isScrolled && $layout === "gradientRed") return tw`bg-gradientRed shadow-xl`;
    if ($isScrolled && $layout === "gradientPurple") return tw`bg-gradientPurple shadow-xl`;
  }}

  ${({ $isScrolled, $layout, $isOpen }) =>
    $isScrolled && $layout === "base"
      ? tw`bg-gradientBase box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`
      : $isScrolled && $layout === "white" && !$isOpen && tw`bg-white box-shadow[0px 4px 4px 0px rgba(0, 0, 0, 0.1)]`}
      
  ${({ $isOpen }) => $isOpen && tw`bg-transparent background-image[none] bg-opacity-0 shadow-none`}
`;

export const MenuContainer = styled.div<{ $isOpen?: boolean }>`
  ${tw`fixed h-full overflow-y-auto invisible opacity-0 flex flex-col justify-between items-start font-serif px-20 pb-30 z-30 inset-0 bg-gradientBase`}
  transition: visibility 400ms ease, opacity 400ms ease;

  ${({ $isOpen }) => $isOpen && tw`visible opacity-100`}
`;

export const MobileNav: React.FC<Props> = ({ layout, isLoading, userData, profileImg }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const isMd = useMediaQuery(up("md"));
  const menuRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();

  useEffect(() => {
    // Close the nav when we navigate from a page to another
    router.events.on("routeChangeStart", () => setIsOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollPosition(({ currPos }) => {
    setIsScrolled(Math.abs(currPos.y) > 20);
  });

  useEffect(() => {
    if (!menuRef?.current) return;
    if (isOpen) disableBodyScroll(menuRef.current);
    else enableBodyScroll(menuRef.current);
  }, [isOpen]);

  useEffect(() => {
    if (isMd) setIsOpen(false);
  }, [isMd]);

  return (
    <>
      <MobileNavbar $isScrolled={isScrolled} $layout={layout || "white"} $isOpen={isOpen}>
        <BrandLink href="/">
          {layout === "white" && !isOpen ? <BlueLogoSVG tw="h-30 lg:h-44" /> : <WhiteLogoSVG tw="h-30 lg:h-44" />}
        </BrandLink>
        <MenuButton onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? (
            <HamburgerCloseSVG tw="w-31 h-32" />
          ) : layout === "white" ? (
            <HamburgerSVGBlue tw="w-31 h-32" />
          ) : (
            <HamburgerSVGWhite tw="w-31 h-32" />
          )}
        </MenuButton>
      </MobileNavbar>
      <MenuContainer $isOpen={isOpen} ref={menuRef}>
        <MenuContent>
          <Ul>
            <Li>
              <JobSearchIconSVG tw="mb-5 mr-10" />
              <Link href="/cerco-lavoro">Cerco lavoro</Link>
            </Li>
            <Li>
              <StaffSearchIconSVG tw="mb-5 mr-10" />
              <Link href="/employer">Cerco staff</Link>
            </Li>
            <ItemDivider />
            <Li>
              <ResponsibilityIconSVG tw="mb-5 mr-10" />
              <Link href="/responsabilita-sociale">Responsabilità sociale</Link>
            </Li>
            <Li>
              <AboutIconSVG tw="mb-5 mr-10" />
              <Link href="/chi-siamo">Chi siamo</Link>
            </Li>
            <ItemDivider />
            <Li>
              <PartnerIconSVG tw="mb-5 mr-10" />
              <Link href="/diventa-partner">Diventa partner</Link>
            </Li>
            <Li>
              <HelpIconSVG tw="mb-5 mr-10" />
              <Link href="/faq">FAQ</Link>
            </Li>
          </Ul>
          {profileImg && profileImg}
          <Button as={Link} href="/login">
            {isLoading ? "Accedi" : !!userData ? "Profilo" : "Accedi"}
          </Button>
        </MenuContent>
        <SocialLinks items={["linkedin", "instagram"]} />
      </MenuContainer>
    </>
  );
};
