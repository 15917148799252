import tw, { styled } from "twin.macro";

type GradientBackground = "transparent" | "gradientBase" | "gradientGreen" | "gradientPurple" | "gradientPurpleLighter";

interface Props {
  background: GradientBackground;
}

const Wrapper = styled.div<{ $background?: GradientBackground }>`
  ${tw`fixed inset-0 z-behind`}
  ${({ $background }) => {
    switch ($background) {
      case "gradientBase":
        return tw`bg-gradientBase`;
      case "gradientGreen":
        return tw`bg-gradientGreen`;
      case "gradientPurple":
        return tw`bg-gradientPurple`;
      case "gradientPurpleLighter":
        return tw`bg-gradientPurpleLighter`;
      default:
        return tw`bg-transparent`;
    }
  }}
`;

export const Gradient: React.FC<Props> = ({ background, ...rest }) => {
  return <Wrapper $background={background} {...rest} />;
};

export type { GradientBackground };
