import { useEffect, useRef } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useKey } from "react-use";
import tw, { styled } from "twin.macro";
import { keyframes } from "styled-components";
import { createPortal } from "react-dom";
import { Video } from "~/components/Base";

import CloseSVG from "~/assets/icons/close-white.svg";

interface Props {
  url: string | undefined;
  isOpen: boolean;
  closeModal: () => void;
}

const WrapperAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackdropAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 90%;
  }
`;

const Wrapper = styled.div`
  ${tw`fixed inset-0 z-90 flex items-center justify-center px-20`}
  animation: ${WrapperAnimation} 300ms ease;
`;

const ModalBackdrop = styled.div`
  ${tw`fixed z-40 w-screen h-full left-0 right-0 top-0 bottom-0 bg-black`}
  animation: ${BackdropAnimation} 300ms ease forwards;
`;

const VideoContainer = tw.div`w-full max-w-6xl z-90`;

const CloseButton = tw.button`absolute top-0 right-0 mt-20 mr-20 lg:mt-60 lg:mr-60 z-100`;

export const VideoModal: React.FC<Props> = ({ isOpen, closeModal, url }) => {
  useKey("Escape", () => closeModal());
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!modalRef?.current) return;
    disableBodyScroll(modalRef.current);
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  if (!isOpen || !url) return null;

  return createPortal(
    <Wrapper>
      <CloseButton onClick={closeModal}>
        <CloseSVG />
      </CloseButton>
      <VideoContainer>
        <Video url={url} controls />
      </VideoContainer>
      <ModalBackdrop onClick={closeModal} />
    </Wrapper>,
    document.body
  );
};
