import tw, { css, styled } from "twin.macro";

interface Props {
  pattern: string;
  opacity?: string;
}

const Wrapper = styled.div<{ $pattern: string; $opacity?: string }>`
  ${tw`absolute top-0 right-0 bottom-0 left-0 w-full h-full z-behind bg-repeat opacity-100`}

  ${({ $pattern }) =>
    !!$pattern &&
    css`
      background-image: url(${$pattern});
      ${tw`opacity-20`}
    `}

  ${({ $opacity }) =>
    !!$opacity &&
    css`
      opacity: ${$opacity};
    `}
`;

export const BackgroundPattern: React.FC<Props> = ({ pattern, opacity, ...rest }) => {
  if (!pattern) return null;
  return <Wrapper $pattern={pattern} $opacity={opacity} {...rest} />;
};
