import tw, { styled } from "twin.macro";

export const Banner = styled.div<{ $withoutCard?: boolean }>`
  ${tw`w-full mb-10 relative mx-auto px-30 py-40 text-grey bg-white border-radius[50px] border border-color[#eaeaea] rounded-bl-none box-shadow[-10px 10px 0px 0px rgba(234, 234, 234, .6)] md:(px-84 py-68)`}

  ${({ $withoutCard }) => {
    if ($withoutCard) {
      return tw`border-none shadow-none`;
    }
  }}}
`;
