import tw, { styled } from "twin.macro";

type FrameBackground = "black" | "white" | "grey";

interface Props {
  background?: FrameBackground;
  hasIllustration?: boolean;
}

const Wrapper = styled.div<{ $background?: FrameBackground; $hasIllustration?: boolean }>`
  ${tw`w-full md:relative mx-auto border-radius[35px] sm:border-radius[50px] max-width[680px] rounded-bl-none md:(mb-200 border border-white px-84 py-68 box-shadow[-20px 20px 0px 0px rgba(0, 0, 0, .2)])`}

  ${({ $background }) => {
    switch ($background) {
      case "black":
        return tw`mb-128 text-white md:(bg-black bg-opacity-30)`;
      case "grey":
        return tw`mb-50 px-21 py-30 sm:px-48 sm:py-40 text-black bg-greyBackground lg:border-0 bg-opacity-90 box-shadow[-10px 10px 0px 0px rgba(0, 0, 0, .2)]`;
      case "white":
      default:
        return tw`mb-50 px-21 py-30 sm:px-48 sm:py-40 text-grey bg-white bg-opacity-90 box-shadow[-10px 10px 0px 0px rgba(0, 0, 0, .2)]`;
    }
  }}
  
  ${({ $hasIllustration }) => $hasIllustration && tw`relative pb-208! md:py-68!`}
`;

export const Frame: React.FC<Props> = ({ children, background, hasIllustration, ...rest }) => {
  return (
    <Wrapper $background={background} $hasIllustration={hasIllustration} {...rest}>
      {children}
    </Wrapper>
  );
};
