import { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import NextHead from "next/head";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { isProd } from "~/utils/common";
import { FB_PIXEL_ID } from "~/utils/fbpixel";

const Title = tw.p`font-bold font-serif text-24 lg:text-30 mb-10 bg-clip-text text-transparent bg-gradient-to-r from-pear to-white`;

const Paragraph = tw.p`text-16`;

const StyledLink = styled.a`
  ${tw`underline hover:underline`}
`;

const CookieWrapper = styled.div`
  ${tw`fixed z-100 w-full max-width[460px] bottom-20 left-10 lg:left-auto right-10 text-white p-20 lg:p-40 border-radius[50px 50px 0 50px]`}
`;

const Wrapper = styled.div`
  .CookieConsent {
    ${tw`right-20! mx-auto! width[90%]! lg:right-20! lg:left-auto!`}
  }
`;

export const CookieBanner: React.FC = () => {
  const [hideBanner, setHideBanner] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [unmount, setUnmount] = useState<boolean>(false);

  const acceptCookie = () => {
    setAccepted(true);
    Cookies.set("cookie-consent", "true", { expires: 150 });
  };

  const rejectCookies = () => {
    setAccepted(false);
    Cookies.remove("cookie-consent");
  };

  useEffect(() => {
    setUnmount(!!Cookies.get("cookie-consent"));
  }, []);

  useEffect(() => {
    if (accepted) setHideBanner(true);
  }, [accepted]);

  if (unmount || hideBanner) {
    return (
      <NextHead>
        {isProd && (
          <>
            {/* Global Site Code Pixel - Facebook Pixel */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${FB_PIXEL_ID});
                  fbq('track', 'PageView');
                `,
              }}
            />
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            {/* <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_ANALYTICS_ID}`} />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_ANALYTICS_ID}', {
                  page_path: window.location.pathname,
                  });
                  `,
              }}
            /> */}
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
              />
            </noscript>
            {/* Hotjar */}
            {/* <script
              dangerouslySetInnerHTML={{
                __html: `
                    (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2302010,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `,
              }}
            /> */}
            {/* Active Campaign */}
            {/* <script
              dangerouslySetInnerHTML={{
                __html: `
                (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
                vgo('setAccount', '1001443768');
                vgo('setTrackByDefault', true);
                vgo('process');`,
              }}
            /> */}
          </>
        )}
      </NextHead>
    );
  }

  return (
    <Wrapper>
      <CookieWrapper>
        <CookieConsent
          buttonText="Accetta"
          cookieName="tagManager"
          debug
          buttonStyle={{
            color: "#365888",
            fontSize: "16px",
            padding: "6px 15px",
            borderRadius: "20px",
            background: "#CFD71B",
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "460px",
            right: "20px",
            margin: "0 0 20px 0",
            left: "auto",
            position: "fixed",
            background: "#365888",
            boxShadow: "-8px 8px 0px rgba(54, 88, 136, 0.25)",
            borderRadius: "50px 50px 0 50px",
            padding: "20px",
            height: "auto",
          }}
          contentStyle={{
            height: "auto",
            flex: "auto",
          }}
          expires={150}
          hideOnAccept
          onAccept={acceptCookie}
          enableDeclineButton
          flipButtons
          declineButtonText="Rifiuta"
          declineButtonStyle={{
            color: "#365888",
            fontSize: "16px",
            padding: "6px 15px",
            borderRadius: "20px",
            background: "#fff",
            marginLeft: 0,
          }}
          onDecline={rejectCookies}
        >
          <Title>Cookies e Privacy</Title>
          <Paragraph>
            Su questo sito utilizziamo cookie tecnici necessari alla navigazione o utili a migliorare i nostri servizi e
            ottimizzare l'esperienza utente. Vai alla{" "}
            <StyledLink href="/Cookie Policy Sito Restworld.pdf" target="_blank">
              Cookie Policy
            </StyledLink>
            .
          </Paragraph>
        </CookieConsent>
      </CookieWrapper>
    </Wrapper>
  );
};
